import React from "react";
import { View, Text, Spacer } from "ollui";

export const Section = ({
  children,
  heading,
  subHeading,
  variant = "default",
  extend,
  ...props
}) => {
  return (
    <View as="section" extend={[rootCSS({ variant }), extend]} {...props}>
      {variant !== "default" ? (
        <View extend={[maxWidthCSS, extend]}>
          <Inner heading={heading} subHeading={subHeading} variant={variant}>
            {children}
          </Inner>
        </View>
      ) : (
        <Inner heading={heading} subHeading={subHeading} variant={variant}>
          {children}
        </Inner>
      )}
      {variant !== "default" && <TriangleTop />}
      {variant !== "default" && <TriangleBottom />}
    </View>
  );
};

export const TriangleTop = () => (
  <View
    as="svg"
    version="1.1"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    extend={triangleTopCSS}
  >
    <path d="M 0,0 0,100 100,0 z" />
  </View>
);
// export const TriangleBottom = () =>  <View extend={triangleBottomCSS} />
export const TriangleBottom = () => (
  <View
    as="svg"
    version="1.1"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    extend={triangleBottomCSS}
  >
    <path d="M 0,100 100,100 100,0 z" />
  </View>
);
const Inner = ({ heading, subHeading, children, variant }) => (
  <>
    {heading && (
      <Text
        variant="medium"
        color={variant === "gradient" ? "white" : "primary"}
        bold
        extend={headingCSS}
      >
        {heading}
      </Text>
    )}
    {subHeading && (
      <>
        <Spacer />
        <Text
          color={variant === "gradient" ? "white" : "body"}
          variant="large"
          bold
        >
          {subHeading}
        </Text>
      </>
    )}
    {(heading || subHeading) && <Spacer size={8} />}
    {children}
  </>
);

const rootCSS = ({ variant }) => ({
  theme: { grid, breakpoints, colors },
}) => ({
  width: "100%",
  position: "relative",
  padding: `${12 * grid}px 0`,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  paddingLeft: 4 * grid,
  paddingRight: 4 * grid,

  extend: [
    {
      condition: variant === "gradient",
      style: {
        background: `linear-gradient(-30deg, ${"#F58C8D"} 0%, ${"#7860D8"} 100%)`,
        width: "100vw",
        // marginLeft: "-50vw",
        left: "50%",
        right: "50%",
        margin: "0 -50vw",
        // paddingTop: 45 * grid,
        // paddingBottom: 45 * grid,
        paddingTop: 150 + 4 * grid,
        paddingBottom: 150 + 4 * grid,
        [breakpoints.onlyS]: {
          paddingTop: 150 + 4 * grid,
          paddingBottom: 150 + 4 * grid,
        },
      },
    },
    {
      condition: variant === "off",
      style: {
        background: colors.contentBg,
        width: "100vw",
        // marginLeft: "-50vw",
        left: "50%",
        right: "50%",
        margin: "0 -50vw",
        paddingTop: 150 + 4 * grid,
        paddingBottom: 150 + 4 * grid,
        [breakpoints.onlyS]: {
          paddingTop: 150 + 4 * grid,
          paddingBottom: 150 + 4 * grid,
        },
      },
    },
  ],

  [breakpoints.onlyS]: {
    paddingLeft: 2 * grid,
    paddingRight: 2 * grid,
  },
  // background: "orange",
});

const triangleTopCSS = ({ theme: { breakpoints, grid } }) => ({
  position: "absolute",
  left: 0,
  top: -1,
  width: "100%",
  height: 150,
  fill: "white",
  // [breakpoints.onlyS]: {
  //   bottom: -100,
  //   height: 200,
  // },
});

const triangleBottomCSS = ({ theme: { colors } }) => ({
  position: "absolute",
  left: 0,
  bottom: -1,
  width: "100%",
  height: 150,
  fill: colors.contentBg,
  // [breakpoints.onlyS]: {
  //   bottom: -100,
  //   height: 200,
  // },
});

const headingCSS = ({ theme: { colors } }) => ({
  // color: colors.primary,
  textTransform: "uppercase",
});

const maxWidthCSS = ({ theme: { layout, grid } }) => ({
  width: "100%",
  maxWidth: layout.maxContentWidth,
  // background: "red",
  margin: "0 auto",
  padding: `0 ${2 * grid}px`,
});
