import React, { useEffect, useCallback, useState } from "react";
import { Link } from "gatsby";
import { View, Text, Link as OlluiLink, Button, Spacer } from "ollui";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from "../img/logo.svg";

{
  /* <AnchorLink to="/about#team" title="Our team">
<span>Check out our team!</span>
</AnchorLink> */
}

export const useHasScrolled = ({ offset = 0 }) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    if (!hasScrolled && window.scrollY > offset) {
      setHasScrolled(true);
      return;
    }
    if (hasScrolled && window.scrollY <= offset) {
      setHasScrolled(false);
    }
  }, [hasScrolled, setHasScrolled, offset]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return hasScrolled;
};

const Navbar = () => {
  const hasSrolled = useHasScrolled({ offset: 90 });

  // toggleHamburger = () => {
  //   // toggle the active boolean in the state
  //   this.setState(
  //     {
  //       active: !this.state.active,
  //     },
  //     // after state has been updated,
  //     () => {
  //       // set the class in state for the navbar accordingly
  //       this.state.active
  //         ? this.setState({
  //             navBarActiveClass: "is-active",
  //           })
  //         : this.setState({
  //             navBarActiveClass: "",
  //           });
  //     }
  //   );
  // };

  return (
    <View extend={rootCSS({ hasSrolled })}>
      <View
        extend={containerCSS}
        as="nav"
        role="navigation"
        aria-label="main-navigation"
      >
        <View extend={linksCSS}>
          <OlluiLink as={AnchorLink} extend={linkCSS} to="/#features">
            <Text>Features</Text>
          </OlluiLink>
          {/* <OlluiLink as={AnchorLink} extend={linkCSS} to="/#contact">
            <Text>Contact</Text>
          </OlluiLink> */}
          <OlluiLink as={Link} extend={linkCSS} to="/privacy">
            <Text>Privacy Policy</Text>
          </OlluiLink>
          {/* <OlluiLink as={Link} extend={linkCSS} to="/blog">
              <Text>Blog</Text>
            </OlluiLink> */}
        </View>
        <View as={Link} to="/" title="Logo" extend={logoCSS}>
          <View as="img" src={logo} alt="Taltrast" extend={logoImageCSS} />
        </View>
        {/* <Spacer size={2} /> */}
        <View extend={buttonContainerCSS}>
          {/* <View
            as="a"
            href="https://app.taltrast.com/login"
            extend={{ textDecoration: "none" }}
          >
            <Button variant="default">Login</Button>
          </View> */}
          {/* <Spacer size={2} />
            <View
              as="a"
              href="https://app.taltrast.com/login"
              extend={{ textDecoration: "none" }}
            >
              <Button>Signup</Button>
            </View> */}
        </View>
      </View>
    </View>
  );
};

const rootCSS = ({ hasSrolled }) => ({ theme: { colors, breakpoints } }) => ({
  position: "fixed",
  top: 0,
  width: "100%",
  // background: "hotpink",
  zIndex: 5,
  background: hasSrolled ? colors.contentBg : "transparent",
  transition: "background 200ms ease-in-out",

  // [breakpoints.onlyS]: {
  //   background: colors.bg,
  // },
});

const containerCSS = ({ theme: { shadows, layout, grid, breakpoints } }) => ({
  maxWidth: layout.maxContentWidth,
  // boxShadow: shadows.big,
  display: "flex",
  // justifyContent: "space-between",
  margin: "0 auto",
  padding: 3 * grid,
  alignItems: "center",

  [breakpoints.onlyS]: {
    // flexDirection: "column",
    width: "100%",
    // background: "orange",
    // justifyContent: "center",
    padding: 1 * grid,
    // paddingTop: 3 * grid,
  },
});

const linksCSS = ({ theme: { shadows, colors, grid, breakpoints } }) => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
  marginRight: 2 * grid,

  [breakpoints.onlyS]: {
    display: "none",
    // position: "fixed",
    // top: 66,
    // left: 0,
    // background: "white",
    // // background: "hotpink",
    // // borderTop: `2px solid ${colors.border}`,
    // // boxShadow: shadows.big,
    // width: "100%",
    // justifyContent: "center",
    // alignItems: "center",
    // // padding: grid,
    // zIndex: 3000000002,
    // paddingRight: 100,
  },
});

const linkCSS = ({ theme: { shadows, layout, grid, breakpoints } }) => ({
  padding: `${grid}px ${2 * grid}px`,
  textDecoration: "none",

  [breakpoints.onlyS]: {
    padding: 2 * grid,
    // border: "1px solid red",
  },
});

const logoCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  flex: 0,

  justifyContent: "center",
  alignItems: "center",
  // margin: "auto",
  // background: "red",

  [breakpoints.onlyS]: {
    width: "100%",
    // background: "red",
    flex: 1,
  },
});

const logoImageCSS = ({ theme: { breakpoints } }) => ({
  height: 80,

  [breakpoints.onlyS]: {
    height: 50,
  },
});

const buttonContainerCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  flex: 1,
  // [breakpoints.onlyS]: {
  //   flexDirection: "column",
  // },
  [breakpoints.onlyS]: {
    display: "none",
  },
});

export default Navbar;
