import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Navbar from "./Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { ThemeProvider, styleRenderer, StyleProvider, View } from "ollui";
import theme from "../theme";
import icon from "/static/img/icon.png";

const renderer = styleRenderer();

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <View extend={rootCSS}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.png`}
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" /> */}

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />

        <script
          async
          defer
          data-domain="taltrast.com"
          src="https://stenkolle.com/tracker.js"
        ></script>

        {/* <script type="text/javascript">{`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5ff9da80c31c9117cb6cffe6/1erjvr1jt';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();`}</script> */}
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </View>
  );
};

const rootCSS = ({ theme: { colors } }) => ({
  // background: "hotpink",
  overflowX: "hidden",
  background: colors.contentBg,
  // paddingTop: 90,

  "& > *": {
    fontFamily: "Roboto, sans-serif",
  },
});

export default TemplateWrapper;
